<template>
  <van-overlay
      :show="loadingOfEquipment || loadingOfCustomer || loadingOfCustomerDepartment"
      @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="确认设备客户信息"
                 left-text="返回"
                 @click-left="router.back"/>
    <div class="page-container">
      <van-cell-group inset title="客户信息">
        <van-field label="客户姓名" readonly :model-value="customer.customerInfo.name"/>
        <van-field label="客户电话" readonly :model-value="customer.customerInfo.cellphone"/>
        <van-field label="客户单位" readonly :model-value="customer.customerDepartmentInfo.name"/>
        <van-field label="客户地址" readonly :model-value="customer.customerInfo.address"/>
      </van-cell-group>

      <van-cell-group inset title="设备信息">
        <van-field label="设备名称" readonly :model-value="customer.equipmentInfo.name"/>
        <van-field label="序列号" readonly :model-value="customer.equipmentInfo.sn"/>
        <van-field label="设备型号" readonly :model-value="customer.equipmentInfo.model"/>
        <van-field label="设备地址" readonly :model-value="customer.equipmentInfo.address"/>
        <van-field label="设备描述" readonly type="textarea" :model-value="customer.equipmentInfo.description"/>
      </van-cell-group>
      <SmpFileCellGroup :files="customer.equipmentInfo.files"/>
      <van-grid clickable :column-num="2">
        <van-grid-item text="填写其他信息报单" icon="arrow-left" @click="repair"/>
        <van-grid-item text="根据以上信息报单" icon="arrow" @click="dispatch" v-if="customer.customerInfo.name"/>
      </van-grid>

    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {queryCustomer, queryCustomerDepartment} from "@/api/customer";
import {queryDevice} from "@/api/device";
import {nameEncryption, phoneEncryption} from "../../../plugin/encryption";
import SmpFileCellGroup from "../../../component/SmpFileCellGroup";

const router = useRouter();
const customer = reactive({
  equipmentInfo: {},
  customerInfo: {},
  customerDepartmentInfo: {}
});
const query = getUrlParam();
const loadingOfEquipment = ref(false);
const loadingOfCustomer = ref(false);
const loadingOfCustomerDepartment = ref(false);

const repair = () => {
  query.origin = "prepare-mismatch";
  router.push({path: "/workorder/prepare", query});
};
const dispatch = () => {
  query.customerId = customer.equipmentInfo.customerId;
  router.push({path: "/workorder/unassigned", query});
};
const requestEquipmentInfo = () => {
  loadingOfEquipment.value = true;
  queryDevice(getUrlParam().id).then(res => {
    customer.equipmentInfo = res.data;
    loadingOfEquipment.value = false;
    requestCustomerInfo();
    requestCustomerDepartmentInfo();
  }).catch(err => {
    loadingOfEquipment.value = false;
    console.error("Request equipment info fail", err);
  });
};
const requestCustomerInfo = () => {
  loadingOfCustomer.value = true;
  queryCustomer(customer.equipmentInfo.customerId).then(res => {
    res.data.cellphone = phoneEncryption(res.data.cellphone);
    res.data.name = nameEncryption(res.data.name);
    customer.customerInfo = res.data;
    loadingOfCustomer.value = false;
  }).catch(err => {
    loadingOfCustomer.value = false;
    console.error("Request customer info fail", err);
  });
};
const requestCustomerDepartmentInfo = () => {
  loadingOfCustomerDepartment.value = true;
  queryCustomerDepartment(customer.equipmentInfo.customerDepartmentId).then(res => {
    customer.customerDepartmentInfo = res.data;
    loadingOfCustomerDepartment.value = false;
  }).catch(err => {
    loadingOfCustomerDepartment.value = false;
    console.error("Request customer department fail", err);
  });
};

onMounted(() => {
  validationUrlParamStrictly();
  requestEquipmentInfo();
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
